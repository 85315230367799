import React from 'react';
import { nanoid } from 'nanoid';
import ObserverSection from '../ObserverSection';

const Section = ({ data }) => {
  if (!data) return null;
  return (
    <ObserverSection
    threshold={0.1}
      className={`builder-section images-${
        data.images && data.images.length > 0 && data.imagesPosition
          ? data.imagesPosition
          : 'under'
      }`}
    >
      {data.text && (
        <div
          className="builder-text-content"
          dangerouslySetInnerHTML={{
            __html: data.text,
          }}
        />
      )}
      {data.images && data.images.length > 0 && (
        <div className="builder-images-wrapper">
          {data.images.map(img => (
            <img
              key={nanoid()}
              className="builder-image"
              alt=""
              src={img.localFile.url}
            />
          ))}
        </div>
      )}
    </ObserverSection>
  );
};

export default Section;
