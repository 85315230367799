import React from 'react';
import { nanoid } from 'nanoid';
import Section from './Section';

const Builder = ({ sections = [] }) => {
  return (
    <div className="builder-wrapper">
      {sections?.map(el => (
        <Section key={nanoid()} data={el} />
      ))}
    </div>
  );
};

export default Builder;
