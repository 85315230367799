import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const ObserverSection = ({ children, className, threshold = 0.3, style }) => {
  const { ref, inView } = useInView({
    threshold,
  });

  const [wasVisible, setWasVisible] = useState(false);

  useEffect(() => {
    if (!wasVisible && inView) {
      setWasVisible(true);
    }
  }, [inView, wasVisible]);

  return (
    <div
      ref={ref}
      className={`observer-section${wasVisible ? ' animate' : ''}${
        className ? ` ${className}` : ''
      }`}
      style={style}
    >
      {children}
    </div>
  );
};

export default ObserverSection;
