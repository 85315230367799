import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

import Seo from '../components/Seo';
import LayoutComponent from '../components/Layout';

const NotFound = () => {

  const intl = useIntl();

  return (
    <LayoutComponent>
      <Seo title={intl.formatMessage({ id: 'general.notFound' })} />
      <div style={{ padding: 20 }}>
        <h1>404: {intl.formatMessage({ id: 'general.notFound' })}</h1>
        <p>{intl.formatMessage({ id: 'general.notFoundContent' })}</p>
      </div>
    </LayoutComponent>
  );
};

export default NotFound;
